export function Birthday() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={process.env.PUBLIC_URL + "/flyer.jpg"}
          className="App-logo"
          alt="gump"
        />
      </header>
    </div>
  );
}
